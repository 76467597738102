<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="8" sm="12">
                  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                    <b-form  @submit.prevent="handleSubmit(register)">
                      <ValidationProvider name="organization" vid="org_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="org_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{$t('warehouse_config.organization')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="report_heading.org_id"
                            :options="orgList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Project Name (En)" vid="project_name" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="heading"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{$t('warehouse_config.project_namef')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="heading"
                            v-model="report_heading.project_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Project Name (Bn)" vid="heading_bn" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="heading_bn"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{$t('warehouse_config.project_name_bn')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="heading_bn"
                            v-model="report_heading.project_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Address (En)" vid="address" rules="required" required>
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="address"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{$t('warehouse_config.addressf')}} <span class="text-danger">*</span>
                        </template>
                          <b-form-textarea
                            id="address"
                            v-model="report_heading.address"
                            rows="1"
                            max-rows="2"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Address (Bn)" vid="address_bn" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('warehouse_config.address_bn')"
                          label-for="address_bn"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{$t('warehouse_config.address_bn')}} <span class="text-danger">*</span>
                        </template>
                          <b-form-textarea
                            id="address_bn"
                            v-model="report_heading.address_bn"
                            rows="1"
                            max-rows="2"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Left Logo" vid="left_logo" :rules="report_heading.id === 1 ? '' : 'required'">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('warehouse_config.left_logo')"
                          label-for="left_logo"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{$t('warehouse_config.left_logo')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-file
                            id="left_logo"
                            v-on:change="onLeftLogoChange"
                            accept="image/*"
                            v-model="report_heading.left_logo"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-file>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Right Logo" vid="right_logo" :rules="report_heading.id === 1 ? '' : 'required'">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="right_logo"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{$t('warehouse_config.right_logo')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-file
                            id="right_logo"
                            v-on:change="onRightLogoChange"
                            accept="image/*"
                            v-model="report_heading.right_logo"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-file>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { reportHeadingStore, reportHeadingUpdate } from '../../api/routes'
import commonAuth from '../CommonAuth'

export default {
  mixins: [commonAuth],
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.report_heading.org_id = this.userCheck()
    if (this.id) {
      const tmp = this.getReportHeadingData()
      this.report_heading = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      test: 0,
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      report_heading: {
        id: '',
        org_id: 0,
        institute_id: 0,
        project_name: '',
        project_name_bn: '',
        left_logo: [],
        right_logo: [],
        address: '',
        address_bn: ''
      },
      upload_left_logo: [],
      upload_right_logo: [],
      institueList: [
        { value: 1, text: this.$t('common_config.barc') },
        { value: 2, text: this.$t('common_config.bsri') },
        { value: 3, text: this.$t('common_config.dae') }
      ]
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    }
  },
  methods: {
    onLeftLogoChange (e) {
      this.upload_left_logo = e.target.files[0]
    },
    onRightLogoChange (e) {
      this.upload_right_logo = e.target.files[0]
    },
    getReportHeadingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: true }

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData()
      Object.keys(this.report_heading).map(key => {
        if (key === 'left_logo') {
          formData.append(key, this.upload_left_logo)
        }
        if (key === 'right_logo') {
          formData.append(key, this.upload_right_logo)
        } else {
          formData.append(key, this.report_heading[key])
        }
      })

      if (this.report_heading.id) {
        formData.append('_method', 'POST')
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, `${reportHeadingUpdate}/${this.id}`, formData, config)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, reportHeadingStore, formData, config)
      }
      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false
      if (result.success) {
        this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
